import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { Links } from '../../constants/routes'
import { OffersPage } from '../OffersPage'
import { MainLayout } from './MainLayout'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HomeActions, HomeSelectors } from '../../redux/reducers/home'
import { LoginPage } from '../LoginPage'
import { RegistrationPage } from '../RegistrationPage'
import { LogoutPage } from '../LogoutPage'
import { ViewOfferPage } from '../ViewOfferPage'
import { EditOfferPage } from '../EditOfferPage'
import { OrdersPage } from '../OrdersPage'
import { CardsPage } from '../CardsPage'
import langContext from '../../locales'
import { LocalizationProvider } from '../../utils/providers'
import { OFFER_ASK_CREATE, OFFER_BID_CREATE } from '../../@types/accesses'
import { WizardPage } from '../WizardPage'
import { useShowingWizzard } from '../../utils/hooks'
import { OrdersActions } from '../../redux/reducers/orders'
import { ViewOrderPage } from '../ViewOrderPage'
import { MsiDataPage } from '../MsiDataPage'
import { QuestionnairePage } from '../QuestionnairePage'
import { UnsignedDocumentsContext } from '../../utils/contexts'

export const Router = () => {
    const dispatch = useDispatch()
    const isLoggedIn = useSelector(HomeSelectors.getIsLoggedIn)
    const isInitialized = useSelector(HomeSelectors.getIsInitialized)
    const locale = useSelector(HomeSelectors.getLocale)
    const user = useSelector(HomeSelectors.getUser)
    const accesses = useSelector(HomeSelectors.getAccesses)
    const regStatus = useSelector(HomeSelectors.getRegStatus)

    const [documentsModalOpen, setDocumentsModalOpen] = useState(false)

    const isShowingWizzard = useShowingWizzard(accesses)

    useEffect(() => {
        dispatch(HomeActions.requestGetCurrentUser())
        dispatch(HomeActions.requestRegStatus())
    }, [])

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(OrdersActions.requestOrders({}))
        }
    }, [isLoggedIn])

    const router = useMemo(
        () =>
            createBrowserRouter(
                isInitialized
                    ? [
                          {
                              element: <MainLayout />,
                              children: isLoggedIn
                                  ? [
                                        {
                                            path: Links.MsiData,
                                            element: <MsiDataPage />,
                                        },
                                        {
                                            path: Links.Questionnaire,
                                            element: <QuestionnairePage />,
                                        },
                                        {
                                            path: Links.Wizard,
                                            element: <WizardPage />,
                                        },
                                        {
                                            path: Links.ViewOffer,
                                            element: <ViewOfferPage />,
                                        },
                                        {
                                            path: Links.ViewOrder,
                                            element: <ViewOrderPage />,
                                        },
                                        ...[
                                            ...(accesses![OFFER_ASK_CREATE]
                                                ? [
                                                      Links.NewAskOffer,
                                                      Links.EditAskOffer,
                                                  ]
                                                : []),
                                            ...(accesses![OFFER_BID_CREATE]
                                                ? [
                                                      Links.NewBidOffer,
                                                      Links.EditBidOffer,
                                                  ]
                                                : []),
                                        ].map((x, i) => ({
                                            key: i,
                                            path: x,
                                            element: <EditOfferPage />,
                                        })),
                                        {
                                            path: Links.Orders,
                                            element: <OrdersPage />,
                                        },
                                        ...[Links.MyOffers, Links.Offers].map(
                                            (x, i) => ({
                                                key: i,
                                                path: x,
                                                element: <OffersPage />,
                                            })
                                        ),
                                        {
                                            path: Links.Cards,
                                            element: <CardsPage />,
                                        },
                                        {
                                            path: Links.Logout,
                                            element: <LogoutPage />,
                                        },
                                        {
                                            path: Links.NonExistent,
                                            element: (
                                                <Navigate to={Links.Offers} />
                                            ),
                                        },
                                    ]
                                  : [
                                        {
                                            path: Links.Login,
                                            element: <LoginPage />,
                                        },
                                        ...(regStatus?.regEnabled
                                            ? [
                                                  {
                                                      path: Links.Registration,
                                                      element: (
                                                          <RegistrationPage />
                                                      ),
                                                  },
                                              ]
                                            : []),
                                        {
                                            path: Links.NonExistent,
                                            element: (
                                                <Navigate to={Links.Login} />
                                            ),
                                        },
                                    ],
                          },
                      ]
                    : [
                          {
                              path: Links.NonExistent,
                              element: null,
                          },
                      ]
            ),
        [isLoggedIn, isInitialized, user, accesses, isShowingWizzard, regStatus]
    )

    return (
        <UnsignedDocumentsContext.Provider
            value={{
                visible: documentsModalOpen,
                setVisible: setDocumentsModalOpen,
            }}
        >
            <LocalizationProvider translation={langContext} locale={locale}>
                <RouterProvider router={router} />
            </LocalizationProvider>
        </UnsignedDocumentsContext.Provider>
    )
}
