import React, { FC, useCallback, useEffect } from 'react'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { HomeActions, HomeSelectors } from '../../redux/reducers/home'

export const PaymentComponent: FC = () => {
    const dispatch = useDispatch()
    const params = useSelector(HomeSelectors.getBepaidAuthParams)

    useEffect(() => {
        dispatch(HomeActions.requestBepaidAuthParams())
    }, [])

    const callback = useCallback(() => {
        let constructorParams = {
            ...params,
            closeWidget: function (status: string) {
                console.debug('close widget callback ' + status)
            },
        }
        new BeGateway(constructorParams).createWidget()
    }, [params])

    return (
        <div>
            <Button disabled={params === undefined} onClick={() => callback()}>
                Привязать карту
            </Button>
        </div>
    )
}
