export enum Links {
    Offers = '/offers',
    Login = '/login',
    Logout = '/logout',
    Registration = '/registration',
    MyOffers = '/my-offers',
    Cards = '/cards',
    ViewOffer = '/offer/:id',
    ViewOrder = '/order/:id',
    Wizard = '/wizard',
    NewBidOffer = '/new-offer/bid',
    NewAskOffer = '/new-offer/ask',
    EditBidOffer = '/edit-offer/bid/:id',
    EditAskOffer = '/edit-offer/ask/:id',
    Orders = '/orders',
    MsiData = '/msi-data',
    Questionnaire = '/questionnaire',
    NonExistent = '*',
}
