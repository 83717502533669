import { ApiResponse, ApisauceInstance } from 'apisauce'
import { AxiosRequestConfig } from 'axios'
import { Dictionary } from '../../@types/common'
import { getAuthHeader } from './auth'
import { throwErrorNotification } from '../../components/NotificationError/ThrowErrorNotification'

export const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Authorization: getAuthHeader(),
}

export enum RequestMethod {
    GET = 'get',
    POST = 'post',
    DELETE = 'delete',
    PUT = 'put',
    PATCH = 'patch',
}

export interface RequestParams {
    headers?: Dictionary
    instance?: ApisauceInstance
    path: string
    params?: any
    method?: RequestMethod
    data?: any
}

type RequestCreate = (
    api: () => ApisauceInstance
) => <T>(
    params: RequestParams,
    axiosConfig?: AxiosRequestConfig
) => Promise<ApiResponse<T, T>>

export const requestCreate: RequestCreate =
    (api: () => ApisauceInstance) =>
    async <T>(
        {
            path = '',
            params = {},
            method = RequestMethod.GET,
            headers = {},
            instance = api(),
            data = undefined,
        } = {},
        axiosConfig = {}
    ) => {
        const reqParams: any = {
            // TODO: fix
            ...axiosConfig,
            headers: { ...instance.headers, ...headers },
        }

        instance.addMonitor((response) => {
            if (!response.ok && response.data.jwtError !== true) {
                if (response.data.message) {
                    throwErrorNotification(
                        'Api Error',
                        response.data.message,
                        10
                    )
                } else {
                    throwErrorNotification()
                }
            }
        })

        if (data) {
            reqParams.data = data
        }

        return instance[method]<T>(path, params, reqParams)
    }
