import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react'
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import { DocumentsModal, Header } from '../../../components'
import styles from './MainLayout.module.scss'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { HomeSelectors } from '../../../redux/reducers/home'
import { useMobile, useUnsignedDocumentsContext } from '../../../utils/contexts'
import { useShowingWizzard } from '../../../utils/hooks'
import { Links } from '../../../constants/routes'
import { throwErrorNotification } from '../../../components/NotificationError/ThrowErrorNotification'
import { MsiActions } from '../../../redux/reducers/msi'
import {
    DocumentsActions,
    DocumentsSelectors,
} from '../../../redux/reducers/documents'
import { OffersActions } from '../../../redux/reducers/offers'

export const MainLayout: FC<PropsWithChildren> = () => {
    const dispatch = useDispatch()
    const isLoggedIn = useSelector(HomeSelectors.getIsLoggedIn)
    const isMobile = useMobile()
    const accesses = useSelector(HomeSelectors.getAccesses)
    const navigate = useNavigate()

    const isShowingWizzard = useShowingWizzard(accesses)
    const documents = useSelector(DocumentsSelectors.getDocuments)
    const { setVisible } = useUnsignedDocumentsContext()

    useEffect(() => {
        if (documents && documents.length > 0) {
            setVisible(true)
        }
    }, [documents])

    useEffect(() => {
        if (isShowingWizzard) {
            navigate(Links.Wizard)
        }
    }, [isShowingWizzard])

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(MsiActions.requestMsiData())
            dispatch(OffersActions.requestOfferVars())
            dispatch(DocumentsActions.requestDocuments())
        }
    }, [isLoggedIn])

    const [searchParams] = useSearchParams()
    const error = useMemo(() => searchParams?.get('error'), [searchParams])

    useEffect(() => {
        if (error) {
            throwErrorNotification('Something went wrong', error)
        }
    }, [error])

    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.loggedIn]: isLoggedIn,
                [styles.isMobile]: isMobile,
            })}
        >
            <Header />
            {documents && <DocumentsModal />}
            <div className={styles.contentWrapper}>
                <Outlet />
            </div>
        </div>
    )
}
