import { Moment } from 'moment'

export type MsiState = {
    msiExists?: boolean
    msiUrl?: string
    msiUrlLoading: boolean
    msiData?: MsiData
    msiDataLoading: boolean
}

export type AddressType = {
    country: string
    region?: string
    district?: string
    unitary_area?: string
    soato?: number
    locality: string
    locality_type: string
    street?: string
    street_type?: string
    house?: string
    building?: string
    apartment?: string
    postal_code?: number
}

export enum IdDocumentType {
    BelarusPassport = '3',
    BelarusResidentCard = '6',
    IdCardBelarus = '15',
    BiometricResidentCard = '16',
    BiometricResidentCardWithoutCitizenship = '17',
}

export enum ResidentFATCA {
    Yes = '1',
    No = '2',
}

export enum LegalStatus {
    Citizen = 'citizen',
    Resident = 'resident',
}

export enum SexType {
    Male = 'male',
    Female = 'female',
}

export type MsiData = {
    createdAt: Moment
    deletedAt: Moment | null
    id: string
    updatedAt: Moment
    user: string
    data: {
        subject: {
            address: AddressType
            address_str?: string
            birth_place: AddressType
            birth_place_str?: string
            birthdate: Moment
            citizenship: string
            legal_status: LegalStatus
            name_be?: {
                family_name_be: string
                given_name_be: string
                middle_name_be: string
            }
            name_en?: {
                family_name_en: string
                given_name_en: string
            }
            name_ru?: {
                family_name_ru: string
                middle_name_ru: string
                given_name_ru: string
            }
            resident: boolean
            sex: SexType
        }
        contact: {
            emails: string[]
            phones: string[]
            phoneSMSVerification: string
            living_address_str: string
            living_address: AddressType
        }
        esiful_residence_place: {
            address_id: string
            full_address: string
            location_id: string
            reg_date: Moment
            street_id: string
        }
        id_document: {
            type: IdDocumentType
            authority: string
            country: string
            series: string
            number: string
            seriesNumber: string
            issueDate: Moment
            expireDate: Moment
        }
        national_id_number: string
        natural_person_tax_info: {
            resident: boolean
            UNP?: string
            residentFATCA?: ResidentFATCA
        }
        user_registration_info: {
            msi_registration: {
                msi_registration_date: Moment
                msi_registration_type: string
            }
            ra_registration_last_update: {
                last_ra_date: Moment
                last_update_date: Moment
            }
            ra_registration_primary: {
                primary_ra_date: Moment
                primary_submission_date: Moment
            }
        }
    }
}
