import React, { FC, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import styles from './MsiDataPage.module.scss'
import { PageTitle } from '../../components'
import { FileOutlined } from '@ant-design/icons'
import { useLocalizationContext, useMobile } from '../../utils/contexts'
import { useSelector } from 'react-redux'
import { MsiSelectors } from '../../redux/reducers/msi'
import { Button, Input, Space, Typography } from 'antd'
import {
    AddressType,
    IdDocumentType,
    MsiData,
} from '../../redux/reducers/msi/types'
import { useNavigate } from 'react-router-dom'
import { Links } from '../../constants/routes'
import { DATE_FORMAT } from '../../constants'
import { countries } from '../../locales'
const { Text, Title } = Typography

export const addressToString = (address: AddressType) => {
    return [
        address.locality_type ? address.locality_type + ' ' : undefined,
        address.locality ? address.locality + ', ' : undefined,
        address.street_type ? address.street_type + ' ' : undefined,
        address.street ? address.street + ' ' : undefined,
        address.house
            ? address.house + (address.building ? '' : ' ')
            : undefined,
        address.building ? address.building + ', ' : undefined,
        address.apartment ? address.apartment + ', ' : undefined,
        address.postal_code,
    ]
        .filter((x) => !!x)
        .join('')
}

export const nameToString = (data: MsiData['data']['subject']) => {
    if (data.name_ru) {
        return [
            data.name_ru.family_name_ru,
            data.name_ru.given_name_ru,
            data.name_ru.middle_name_ru,
        ]
            .filter((x) => !!x)
            .join(' ')
    } else if (data.name_be) {
        return [
            data.name_be.family_name_be,
            data.name_be.given_name_be,
            data.name_be.middle_name_be,
        ]
            .filter((x) => !!x)
            .join(' ')
    } else if (data.name_en) {
        return data.name_en.family_name_en + ' ' + data.name_en.given_name_en
    } else {
        return ''
    }
}

const idDocumentMappings = {
    [IdDocumentType.BelarusPassport]: 'Паспорт Республики Беларусь',
    [IdDocumentType.BelarusResidentCard]: 'Карта резидента Республики Беларусь',
    [IdDocumentType.BiometricResidentCard]: 'Биометрическая карта резидента',
    [IdDocumentType.BiometricResidentCardWithoutCitizenship]:
        'Биометрическая карта резидента без гражданства',
    [IdDocumentType.IdCardBelarus]: 'Id карта Республики Беларусь',
}

export const MsiDataPage: FC = () => {
    const isMobile = useMobile()
    const navigate = useNavigate()
    const { localize } = useLocalizationContext()
    const msiData = useSelector(MsiSelectors.getMsiData)
    const msiDataLoading = useSelector(MsiSelectors.getMsiDataLoading)
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        if (msiData) {
            setIsLoaded(true)
        }
    }, [msiData])

    return (
        <div
            className={classNames(styles.pageContainer, {
                [styles.pageContainerMobile]: isMobile,
            })}
        >
            <PageTitle title={'Данные из МСИ'} icon={<FileOutlined />} />
            {isLoaded && (
                <>
                    {msiData ? (
                        <Space direction={'vertical'} style={{ width: '100%' }}>
                            <Title level={3}>Личные данные</Title>
                            <Space
                                direction={'horizontal'}
                                style={{ width: '100%' }}
                            >
                                <Space
                                    direction={'vertical'}
                                    style={{ width: '100%' }}
                                >
                                    <Text>Фамилия:</Text>
                                    <Input
                                        disabled
                                        value={
                                            msiData.data.subject.name_ru
                                                ?.family_name_ru ||
                                            msiData.data.subject.name_be
                                                ?.family_name_be ||
                                            msiData.data.subject.name_en
                                                ?.family_name_en ||
                                            ''
                                        }
                                    />
                                </Space>
                                <Space
                                    direction={'vertical'}
                                    style={{ width: '100%' }}
                                >
                                    <Text>Имя:</Text>
                                    <Input
                                        disabled
                                        value={
                                            msiData.data.subject.name_ru
                                                ?.given_name_ru ||
                                            msiData.data.subject.name_be
                                                ?.given_name_be ||
                                            msiData.data.subject.name_en
                                                ?.given_name_en ||
                                            ''
                                        }
                                    />
                                </Space>
                                <Space
                                    direction={'vertical'}
                                    style={{ width: '100%' }}
                                >
                                    <Text>Отчество:</Text>
                                    <Input
                                        disabled
                                        value={
                                            msiData.data.subject.name_ru
                                                ?.middle_name_ru ||
                                            msiData.data.subject.name_be
                                                ?.middle_name_be ||
                                            ''
                                        }
                                    />
                                </Space>
                            </Space>
                            <Space direction={'horizontal'}>
                                <Space direction={'vertical'}>
                                    <Text>Дата рождения:</Text>
                                    <Input
                                        disabled
                                        value={msiData.data.subject.birthdate.format(
                                            DATE_FORMAT
                                        )}
                                    />
                                </Space>
                            </Space>
                            {msiData.data.subject.birth_place && (
                                <Space
                                    direction={'horizontal'}
                                    style={{ width: '100%' }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Место рождения:</Text>
                                        <Input
                                            disabled
                                            value={addressToString(
                                                msiData.data.subject.birth_place
                                            )}
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                            )}
                            {msiData.data.subject.address && (
                                <Space
                                    direction={'horizontal'}
                                    style={{ width: '100%' }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Место жительства:</Text>
                                        <Input
                                            disabled
                                            value={addressToString(
                                                msiData.data.subject.address
                                            )}
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                            )}
                            {msiData.data.id_document.country && (
                                <Space
                                    direction={'horizontal'}
                                    style={{ width: '100%' }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Гражданство:</Text>
                                        <Input
                                            disabled
                                            value={
                                                countries.data.find(
                                                    (x) =>
                                                        x.code ===
                                                        msiData.data.id_document
                                                            .country
                                                )?.fullname
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                            )}
                            <br />
                            <Title level={3}>
                                Информация о документе, удостоверяющем личность
                            </Title>
                            {msiData.data.subject.name_en && (
                                <Space
                                    direction={'horizontal'}
                                    style={{ width: '100%' }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>
                                            Фамилия, собственное имя на латинице
                                            в соответствии с документом,
                                            удостоверяющим личность
                                        </Text>
                                        <Input
                                            disabled
                                            value={
                                                msiData.data.subject.name_en
                                                    .family_name_en +
                                                ' ' +
                                                msiData.data.subject.name_en
                                                    .given_name_en
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                            )}
                            {msiData.data.id_document && (
                                <Space
                                    direction={'horizontal'}
                                    style={{ width: '100%' }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>
                                            Вид документа, удостоверяющего
                                            личность
                                        </Text>
                                        <Input
                                            disabled
                                            value={
                                                idDocumentMappings[
                                                    msiData.data.id_document
                                                        .type
                                                ]
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Страна выдачи</Text>
                                        <Input
                                            disabled
                                            value={
                                                msiData.data.id_document.country
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                            )}
                            {msiData.data.id_document && (
                                <Space
                                    direction={'horizontal'}
                                    style={{ width: '100%' }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>
                                            Орган, выдавший документ,
                                            улостоверяющий личность
                                        </Text>
                                        <Input
                                            disabled
                                            value={
                                                msiData.data.id_document
                                                    .authority
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>
                                            Реквизиты документа, улостоверяющего
                                            личность (идентификационный номер)
                                        </Text>
                                        <Input
                                            disabled
                                            value={
                                                msiData.data.national_id_number
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                            )}
                            {msiData.data.id_document && (
                                <Space
                                    direction={'horizontal'}
                                    style={{ width: '100%' }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Серия</Text>
                                        <Input
                                            disabled
                                            value={
                                                msiData.data.id_document.series
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Номер</Text>
                                        <Input
                                            disabled
                                            value={
                                                msiData.data.id_document.number
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                            )}
                            {msiData.data.id_document && (
                                <Space
                                    direction={'horizontal'}
                                    style={{ width: '100%' }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Дата выдачи</Text>
                                        <Input
                                            disabled
                                            value={msiData.data.id_document.issueDate.format(
                                                DATE_FORMAT
                                            )}
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Срок действия</Text>
                                        <Input
                                            disabled
                                            value={msiData.data.id_document.expireDate.format(
                                                DATE_FORMAT
                                            )}
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                            )}
                            <br />
                            <Title level={3}>Контактные данные</Title>
                            {msiData.data.contact && (
                                <Space
                                    direction={'horizontal'}
                                    style={{
                                        width: '100%',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>
                                            Номер мобильного тепефона,
                                            использующийся для аутентификации
                                        </Text>
                                        <Input
                                            disabled
                                            value={
                                                msiData.data.contact
                                                    .phoneSMSVerification
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>
                                            Иные номера контактных телефонов
                                        </Text>
                                        <Input
                                            disabled
                                            value={msiData.data.contact.phones
                                                .filter(
                                                    (x) =>
                                                        x ===
                                                        msiData.data.contact
                                                            .phoneSMSVerification
                                                )
                                                .join(', ')}
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Адрес электронной почты</Text>
                                        <Input
                                            disabled
                                            value={
                                                msiData.data.contact.emails
                                                    .length > 0
                                                    ? msiData.data.contact
                                                          .emails[0]
                                                    : ''
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                            )}
                        </Space>
                    ) : (
                        <Space direction={'vertical'}>
                            <Text>{localize('noVerification')}</Text>
                            <Button
                                type={'primary'}
                                disabled={msiDataLoading}
                                onClick={() => navigate(Links.Wizard)}
                            >
                                {localize('goToVerification')}
                            </Button>
                        </Space>
                    )}
                </>
            )}
        </div>
    )
}
